import React, { useRef, useState } from "react";
import arrowLeft from "../assets/logo/Arrow - Right.svg";
import phones from "../assets/images/phones.png";
import mask from "../assets/logo/Mask_group.svg";
import phoneBorder from "../assets/logo/phoneBorder.svg";
import CaptchaValidation from "./captchaValidation";
import useJoinWaitlist from "../services/useJoinWaitlist";
import { toast } from "react-toastify";
import Header from "./header";
import Footer from "./footer";

const ComingSoon = () => {
  const [notifyMe, setNotifyMe] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [email, setEmail] = useState("");
  const emailInputRef = useRef(null);

  const handleButtonClick = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  const { joinWaitlist, loading } = useJoinWaitlist(() => {
    setEmail("");
    setCaptcha(true);
    setNotifyMe(false);
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email.trim() === "") {
      toast.error("Email is required!");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email address!");
      return;
    }

    joinWaitlist(email);
    setEmail("");
  };

  return (
    <div className="flex flex-col h-screen bg-radial-gradient">
      {/* Header */}
      <Header handleButtonClick={handleButtonClick} />
      {/* Main Content */}
      <main className="flex flex-col pt-[9px] items-center md:pt-[50px]">
        <div className="flex flex-col font-campton items-center py-[97px] flex-grow text-center h-full overflow-hidden w-3/4">
          <h1 className="text-2xl font-bold text-gray-800 mb-4 whitespace-normal max-w-xl tracking-wide leading-normal md:text-5xl">
            Ready to level up?
          </h1>
          <h1 className="text-2xl font-bold text-gray-800 whitespace-normal max-w-2xl tracking-wide leading-normal md:text-5xl">
            We&apos;re building this for you
          </h1>
          <p className="font-normal text-gray-800 py-[64px] text-lg md:text-xl max-w-5xl">
            Grindbox is your key to transforming your skills and making your
            dreams a reality. From expert-led lessons to live sessions and
            masterclasses, we&apos;re dedicated to giving you the tools you need
            to grow, create, and thrive in your chosen path. Stay tuned as we
            bring this vision to life!
          </p>
          {!notifyMe && !captcha && (
            <div className="w-full flex flex-col justify-center mx-auto md:w-3/4">
              <div className="flex items-center border justify-between border-gray-300 bg-white rounded-full w-full overflow-hidden mx-auto shadow-sm focus:ring-2 focus:ring-blue-500 px-2 py-2 xl:px-4 xl:w-3/4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Type email address here"
                  className=" text-gray-700 w-full p-2 focus:outline-none md:p-6 md:h-12 md:w-4/6"
                  ref={emailInputRef}
                />
                <button
                  className={`items-center w-[35%] justify-between gap-2 p-3 bg-[#006bb3] text-white rounded-3xl hidden md:flex 2xl:w-[20%] ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleSubmit}
                >
                  {loading ? "Processing" : "Join waitlist"}
                  <img src={arrowLeft} alt="arror left" />
                </button>
              </div>
              <button
                className={`items-center mt-4 w-[65%] justify-center mx-auto gap-4 p-4 bg-[#006bb3] text-white rounded-3xl flex md:hidden ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleSubmit}
              >
                {loading ? "Processing" : "Join waitlist"}
              </button>
            </div>
          )}
          {captcha && <CaptchaValidation setCaptcha={setCaptcha} />}
          <img
            src={mask}
            alt="phones"
            className="absolute top-[55%] right-[24%] h-[10%] z-0 hidden lg:flex"
          />
        </div>
        <div className="relative z-10 pb-10 mx-4 lg:mx-0">
          <img
            src={phones}
            alt="phones"
            className="h-[250px] w-[375px] md:w-full md:h-[95%]"
          />
        </div>

        <img
          src={phoneBorder}
          alt="phones"
          className="absolute top-[135%] left-[15%] h-[20%] z-0 hidden xl:flex"
        />
      </main>
      <Footer />
    </div>
  );
};

export default ComingSoon;
