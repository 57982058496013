import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className="w-full text-center p-4 bg-transparent text-gray-900">
        <p> Grindbox &copy; 2024 | All Rights Reserved</p>
      </footer>
    </div>
  );
}

export default Footer