import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useJoinWaitlist = (onSuccess) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const joinWaitlist = async (email) => {
    setLoading(true);
    setError(null);
    setSuccessMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_WAITLIST_ENDPOINT}/waitlisting`,
        {
          email
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log(response);
       if (onSuccess) onSuccess();
      } else {
        throw new Error("Unexpected response status: " + response.status);
      }
    } catch (err) {
      const message =
        err.response?.data?.errors?.message[0] || "Something went wrong!";
      toast.error(message, { autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  return {
    joinWaitlist,
    loading,
    error,
    successMessage
  };
};

export default useJoinWaitlist;
