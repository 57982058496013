import React from 'react'
import GrindboxLogo from "../assets/logo/Grindbox_Logo.svg";
import arrowLeft from "../assets/logo/Arrow - Right.svg";
const Header = ({ handleButtonClick }) => {
  return (
    <div>
      <header className="fixed top-0 w-full bg-white bg-opacity-70 flex justify-between py-6 px-10">
        <img
          src={GrindboxLogo}
          alt="Grindbox Logo"
          className="w-28 h-auto md:w-72"
        />
        <button
          onClick={handleButtonClick}
          className="items-center gap-4 p-4 bg-[#006bb3] text-white rounded-3xl hidden md:flex"
        >
          Join waitlist <img src={arrowLeft} alt="arror left" />
        </button>
      </header>
    </div>
  );
};

export default Header